.back_hospedaje{
    background-image: url('../images/tercera_reunion/HOSPEDAJE/back_hospedaje_ampip.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3% 0%;
}
.text_safi{
    font-size: 16px;
    font-weight: bold;
}
.text_safi_ubicacion{
    font-size: 10px;
}