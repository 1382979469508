.contenedor_inical{
    position: relative;
}
.contenedor_banner_menu{
    padding-top: 120px;
}
.img_banner_inicial{
    width: 100%;
    position: relative;
}

.boton_registro_inicial{
    width: 150px;
    position: absolute;
    z-index: 99;
    bottom: 20%;
    right: 10%;
}

.boton_registro_calendar{
    width: 150px;
    position: absolute;
    z-index: 99;
    bottom: 20%;
    right: 30%;
}

.dudas_btn{
    color:#ffffff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
}

.datos_back{
    background-image: url('../images/segunda_reunion/DATOS/datos_fondo_ampip.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;
}
@media screen and (max-width: 767px) {
    .boton_registro_inicial{
        width: 100px;
        position: absolute;
        z-index: 99;
        }

        .boton_registro_calendar{
            width: 100px;
            position: absolute;
            z-index: 99;
            }
            .contenedor_banner_menu{
                padding-top: 50px;
            }
}


@media screen and (max-width: 565px) {
    .boton_registro_inicial{
        width: 60px;
        position: absolute;
        z-index: 99;
        bottom: 10%;
        }

        .boton_registro_calendar{
            width: 60px;
            position: absolute;
            z-index: 99;
            bottom: 10%;
            }
            .dudas_btn{
                font-size: 14px;
            }
}