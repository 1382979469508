.container{
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  display: inline-flex;
  width: 100%;
}

.questionContainer{
  margin: 15px 5px;
  padding:15px;
  background-color: #ffffff;
}

.acceptButton{
  background-color:rgb(65,176,44);
  color: white;
  margin: 10px;
    border: none;
    padding: 5px;
    font-weight: bold;
}

.answerButton{
  background-color:rgb(83,167,225);
  color: white;
    border: none;
    padding: 5px;
    font-weight: bold;
}
.admin_preguntas_contendor{
  background-color: rgb(237,237,237);
}
.retrieveButton{
  background-color:rgb(240, 213, 47);
  color: white;
    border: none;
    padding: 5px;
    font-weight: bold;
}

.rejectButton{
  background-color:	rgb(218, 0, 35);
  color: white;
    border: none;
    padding: 5px;
    font-weight: bold;
}
.botones{
  text-align: right;
  margin:0px;
}
.question{
  font-size: 15px;
  text-align: left;
  margin:0px;
}
.nombre_pregunta{
  font-style: italic;
  font-weight: bold;
}
.admin_titulo{
color: #000000;
font-size: 44px;
font-weight: bold;
margin-bottom: 0px;
padding-bottom: 0px;
}
.admin_encabezado{
  background-color: #000000;
  border-right: 2px solid #ffffff;
}
.admin_texto_encabezado{
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
}